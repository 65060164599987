@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,600;0,700;1,700&display=swap');
@import 'normalize.css';
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

p,
span,
h1,
h2,
h3,
h4 {
  font-family: 'Chakra Petch', sans-serif;
  color: #ffffff;
}

:root {
  --text-blue: #90bde3;
  --border-color: rgba(255, 255, 255, 0.2);
  --border-width: 0.1rem;
  --borders: var(--border-width) solid var(--border-color);

  --border-disabled-color: rgba(255, 255, 255, 0.2);
  --button-bg-color: rgba(4, 4, 4, 0.6);
  --button-disabled-bg-color: rgba(11, 11, 11, 0.4);
  --button-green-bg-color: rgb(110 194 120);
  --button-red-bg-color: rgb(238 41 35);
  --button-green-border-color: rgba(100, 194, 120);
  --button-text-color: rgba(255, 255, 255, 1);
  --button-default-bg-color: rgba(0, 0, 0, 1);
  background-color: black;

  --chip-row-selected-border: #75deff;
  --opaque-background: linear-gradient(
      0deg,
      rgba(102, 102, 102, 0.19),
      rgba(102, 102, 102, 0.19)
    ),
    radial-gradient(
      112.5% 112.5% at 50% 15.28%,
      rgba(12, 12, 12, 0.85) 24.5%,
      rgba(0, 0, 0, 0.374) 100%
    );
  --chat-border: #63839e;
  --chat-bubble-info: #898989;
  --chat-text-color: #d9d9d9;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

html,
body {
  /*font-size: calc(((100vw) / 1920) * 10);*/
  font-size: calc((min(100vw, 177vh) / 1920) * 10);
}
